











































































































































































































































































































































































































.getStatisticsData {
  margin-bottom: 10px;
  font-size: 13px;

  .amountOfMoney {
    font-size: 18px;
    font-weight: 600;
    color: red;
    margin-right: 10px;
  }
}

.sh_1 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
}

.sh_2 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.sh_3 {
  margin-bottom: 4px;
}
.text_1 {
  font-size: 16px;
  font-weight: 600;
  color: red;
}
